import React from 'react';

import "./Home.css"
import "./HomePage3.css"

const HomePage3 = () => {
    return (
        <div className="home-item home-view-3">
            <div className='homeContent3'>
                <div className="homeWrap">
                    <div className="homeText homeText3">Create intuitive and beautiful products</div>
                    <div className="homeSubtext homeSubtext3"></div>
                </div>
            </div>
        </div>
    );
};

export default HomePage3;