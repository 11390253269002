import React from 'react';

import "./Home.css"
import TinySlider from "./TinySlider";

const Home = () => {
    return (
        <div className="homeContainer">
            <div className="home">
                <TinySlider/>
            </div>
        </div>
    );
};

export default Home;