import React, {Component} from 'react'
import "./Blog.css"
import {routeState} from "../navigation/const";
import {NavLink, withRouter} from "react-router-dom";
import {formatDate} from "../util/Util";
import {format} from 'react-string-format';
import {postIconUrl, postsUrl} from "../util/BlogApi";
import ButtonBase from "@material-ui/core/ButtonBase";
import {FaHeart, FiHeart} from 'react-icons/all';
import Button from "@material-ui/core/Button";
import {getCookie} from "../util/CookieUtil";
import {likePost} from "../post/Post";
import {FaRegComment} from "react-icons/fa";

class Blog extends Component {

    state = {
        newLike: false,
        posts: [],
        width: '',
        height: '',
    };

    componentDidMount() {
        fetch(postsUrl)
            .then(res => {
                if (res.status !== 200) {
                    throw new Error('Failed to fetch posts.');
                }
                return res.json()
            })
            .then(res => {
                this.setState({posts: res.reverse()});
            })
            .catch(error => {
                console.log(error);
            });
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions)
    }

    updateWindowDimensions = () => {
        this.setState({width: window.innerWidth, height: window.innerHeight});
    };

    getSummary(summary) {
        const innerWidth = window.innerWidth;
        let length = this.getSummaryLength(innerWidth);
        return summary.length >= length ? this.getEllipsisSummary(length, summary) : summary;
    }

    getSummaryLength(innerWidth) {
        let length = 175;
        if (innerWidth > 850) {
        } else if (innerWidth > 700) {
            length = 140;
        } else if (innerWidth > 600) {
            length = 100;
        } else if (innerWidth > 400) {
            length = 55;
        } else if (400 >= innerWidth) {
            length = 40;
        }
        return length
    }

    getEllipsisSummary(length, summary) {
        summary = summary.substring(0, length);
        let lastIndex = summary.lastIndexOf(" ");
        return summary.substring(0, lastIndex) + " ...";
    }

    mouseDown = e => {
        e.stopPropagation();
    };

    onLikeClick = (event, postJson) => {
        event.preventDefault();
        likePost(postJson, (postJson, newLike) => {
                this.setState({
                    newLike: newLike,
                    postJson: postJson
                });
            }
        );
    };

    render() {
        return (
            <div className="blog">
                <ul>
                    {this.state.posts.map((post) => (
                        <li key={post.id}>
                            <ButtonBase className="fakeLi postItem">
                                <NavLink className="wrapPostItem" to={{
                                    pathname: routeState.BLOG + '/' + post.id,
                                    state: {post: post}
                                }}>
                                    <div className="wrapText">
                                        <p className="postTitle">{post.title}</p>
                                        <p className="postSummary">{this.getSummary(post.summary)}</p>
                                        {/*<div className="postAuthor">{post.author}</div>*/}
                                        <div className="postRowBottom">
                                            <div className="postRowLikeCount">
                                                <Button
                                                    onMouseDown={this.mouseDown}
                                                    onClick={e => this.onLikeClick(e, post)}>
                                                    <LikeIcon id={post.id}/>
                                                    <p>{post.likeCount} </p>
                                                </Button>
                                                <Button>
                                                    <FaRegComment className="postRowCommentIcon"/>
                                                    <p>{post.commentCount} </p>
                                                </Button>
                                            </div>
                                            <div className="postDate">{formatDate(post.date)}</div>
                                        </div>
                                    </div>
                                    <img src={format(postIconUrl, post.icon)} alt={post.title}/>
                                </NavLink>
                            </ButtonBase>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

function LikeIcon(postJson) {
    if (getCookie("cookiePost" + postJson.id) === 'true') {
        return <FaHeart className="postRowLikeIcon"/>
    } else {
        return <FiHeart className="postRowLikeIcon"/>
    }
}

export default withRouter(Blog)