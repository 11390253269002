import React, {Component} from "react";
import PrivacyPolicyMd from "./PrivacyPolicy.md";
import ReactMarkdown from "react-markdown";
import CodeBlock from "../post/CodeBlock";

class PrivacyPolicy extends Component {

    state = {
        markdown: '',
    };

    componentDidMount() {
        fetch(PrivacyPolicyMd).then(res => res.text()).then(text => this.setState({markdown: text}));
    }

    render() {
        const {markdown} = this.state;
        return (
            <div className='wrap'>
                <div className="postBody">
                    <div className='post'>
                        <ReactMarkdown
                            source={markdown}
                            renderers={{
                                code: CodeBlock,
                                image: Image
                            }}
                            escapeHtml={false}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default PrivacyPolicy;