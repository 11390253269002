import React, {Component, Fragment} from 'react'
import "./Contact.css"
import {withRouter} from "react-router-dom";
import {contactUrl} from "../util/BlogApi";
import PopUp from "../components/PopUp";
import {PopUpState} from "../components/PopUpState";
import ProgressPopUp from "../components/ProgressPopUp";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {withStyles} from "@material-ui/styles";
import grey from "@material-ui/core/colors/grey";
import amber from "@material-ui/core/colors/amber";

class Contact extends Component {

    state = {
        progress: false,
        popUp: null,
        name: '',
        email: '',
        subject: '',
        message: '',
        msgLength: '0'
    };

    componentDidMount() {
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.setState({progress: true});
        document.getElementById('submit').blur();
        document.getElementById("contact-form").reset();
        this.setState({msgLength: '0'});

        fetch(contactUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: this.state.name,
                email: this.state.email,
                subject: this.state.subject,
                message: this.state.message,
            })
        })
            .then(res => {
                if (res.status === 422) {
                    throw new Error("Validation failed. Please check your inputs and try again.");
                }
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error('Failed to send your message. Please try again later.');
                }
                return res.json();
            })
            .then(resData => {
                this.showPopUp('Thank you!', 'The contact form was sent successfully. I will be contacting you soon', false)
            })
            .catch(err => {
                this.showPopUp('Error', err.message, false);
                console.error(err);
            });
    };

    handleChangeName = (event) => {
        this.setState({name: event.target.value});
    };

    handleChangeEmail = (event) => {
        this.setState({email: event.target.value});
    };

    handleChangeTitle = (event) => {
        this.setState({subject: event.target.value});
    };

    handleChangeMsg = (event) => {
        this.setState({message: event.target.value});
        this.setState({msgLength: event.target.value.length});
    };

    showPopUp = (title, subtitle, isCancelVisible) => {
        const testModalState = new PopUpState(
            title,
            subtitle,
            isCancelVisible,
            () => {
                this.setState({popUp: null})
            },
            () => {
                this.setState({popUp: null})
            },
            () => {
                this.setState({popUp: null})
            },
        );
        this.setState({progress: false});
        this.setState({popUp: testModalState});
    };

    render() {
        return (
            <Fragment>
                <ProgressPopUp progress={this.state.progress}/>
                <PopUp popUp={this.state.popUp}/>
                <div className="contact">
                    <div className='contactCard'>
                        <div className='title'>Contact</div>
                        <form id='contact-form' onSubmit={this.onSubmit}>
                            <div className='contactContainer'>
                                <div className='inputWrap'>
                                    <FormTextField variant="outlined"
                                                   className='contactInput'
                                                   label='Enter your name'
                                                   onChange={this.handleChangeName}
                                                   type='text'
                                                   required
                                    />
                                </div>
                                <div className='inputWrap'>
                                    <FormTextField variant="outlined"
                                                   className='contactInput'
                                                   label='Enter your email'
                                                   pattern="^.+@.+\.[a-zA-Z]{2,63}$"
                                                   onChange={this.handleChangeEmail}
                                                   type='email'
                                                   required
                                    />
                                </div>
                                <div className='inputWrap'>
                                    <FormTextField variant="outlined"
                                                   className='contactInput'
                                                   label='Enter subject'
                                                   onChange={this.handleChangeTitle}
                                                   type='text'
                                                   required
                                    />
                                </div>
                                <div className='inputWrap'>
                                    <FormTextField multiline
                                                   className='contactTextarea'
                                                   variant="outlined"
                                                   rows='10'
                                                   maxLength='500'
                                                   label='Enter your message'
                                                   onChange={this.handleChangeMsg}
                                                   required
                                    />
                                    <div className='msgMaxLength'>{this.state.msgLength + "/500"}</div>
                                </div>
                                <Grid container justify='center'>
                                    <Button variant="contained"
                                            color="primary"
                                            id='submit'
                                            type='submit'>Submit</Button>
                                </Grid>
                            </div>
                        </form>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const FormTextField = withStyles({
    root: {
        "& .Mui-focused": {
            color: grey[700],
        },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: amber[800],
        }
    }
})(TextField);


export default withRouter(Contact)