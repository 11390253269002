const Links = [
    {
        label: 'Github',
        icon: 'Github',
        url: `https://github.com/QArtur99`
    },
    {
        label: 'LinkedIn',
        icon: 'LinkedinSquare',
        url: `https://www.linkedin.com/in/artur-gniewowski`
    },
    {
        label: 'Medium',
        icon: 'Medium',
        url: `https://medium.com/@arturgniewowski`
    },
    {
        label: 'Twitter',
        icon: 'Twitter',
        url: `https://twitter.com/AGniewowski`
    }
];

export default Links;