import React from 'react';

import Links from './Links';
import Icons from './Icons';

import "./SocialLinks.css"
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 6,
        minWidth: 48,
        color: grey[50],
        textTransform: 'initial',
        "&:focus": {
            outline: 0
        },
        '&:hover': {
            color: grey[900],
            backgroundColor: theme.palette.primary.main,
        }
    },
    noDecoration: {
        textDecoration: "none !important"
    }
}));

const SocialLinks = () => {
    const classes = useStyles();
    return (
        <div className="socialLinks">
            <ul>
                {Links.map((link, i) => {
                    const Icon = Icons[link.icon];
                    return (
                        <li key={i}>
                            <a className={classes.noDecoration} href={link.url} title={link.label}>
                                <Button
                                    className={classes.root}
                                    color="secondary"
                                    size="large"
                                    classes={{text: classes.root}}
                                >
                                    <Icon/>
                                </Button>
                            </a>
                        </li>
                    )
                })}
            </ul>
        </div>
    );
};

export default SocialLinks;