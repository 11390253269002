import React, {Component, Fragment} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/styles";
import grey from "@material-ui/core/colors/grey";
import TextField from "@material-ui/core/TextField";
import {Divider} from "@material-ui/core";
import {FiHeart} from 'react-icons/fi';
import {FaHeart, FaRegComment} from 'react-icons/fa';
import "./CommentPanel.css"
import {postAddComment, postCommentLike} from "../util/BlogApi";
import {getCookie, setCookie} from "../util/CookieUtil";

const styles = theme => ({
    noDecoration: {
        margin: 0,
        marginBottom: 16,
        marginTop: 16,
        textDecoration: "none !important"
    },
    commentContainer: {
        margin: 0,
        marginBottom: 16,
        marginTop: 16,
        textDecoration: "none !important"
    },
    commentTittle: {
        margin: 0,
        textDecoration: "none !important"
    },
    commentBody: {
        margin: 0,
        textDecoration: "none !important"
    },
    container: {
        marginBottom: 32,
    }
});

class CommentPanel extends Component {

    form;
    state = {
        author: '',
        message: '',
        comment: null
    };

    handleChangeMsg = (event) => {
        this.setState({message: event.target.value});
    };

    handleChangeName = (event) => {
        this.setState({author: event.target.value});
    };

    onLikeComment = (commentJson) => {
        likeComment(
            commentJson, (postJson, newLike) => {
                this.setState({newLike: newLike});
            }
        );
    };

    onSubmit = (e) => {
        e.preventDefault();
        addComment(
            this.props.postJson.id,
            this.isFromComment(),
            this.state.author,
            this.state.message,
            (comments) => {
                this.props.updateCommentCount(comments);
                this.setState({
                    author: '',
                    message: ''
                });
            }
        );
    };

    isFromComment() {
        let commentId = null;
        if (this.state.comment) {
            commentId = this.state.comment.id;
            const match = this.state.message.match('@' + this.state.comment.author);
            if (match.index !== 0) {
                commentId = null
            }
        }
        return commentId;
    }

    onResponse(comment) {
        this.scrollToCommentPanel();
        this.setState({
            message: '@' + comment.author + " ",
            comment: comment
        });
        this.form.focus();
    };

    scrollToCommentPanel() {
        const commentPanel = document.querySelector('.commentPanel');
        const appHeader = document.querySelector('.app-header');
        window.scroll({
            top: commentPanel.offsetTop - appHeader.clientHeight,
            behavior: 'smooth',
        });
    }

    render() {
        const {classes} = this.props;
        return (<Fragment>
                <Grid container direction="column" className={classes.container + " commentPanel"} spacing={2}>
                    <Grid item>
                        <Typography variant="h5"
                                    paragraph
                                    style={{fontWeight: 700}}
                                    className={classes.noDecoration + " text-black"}>
                            Responses ({this.props.postJson.commentCount})
                        </Typography>
                    </Grid>
                    <form id='contact-form' onSubmit={this.onSubmit}>
                        <Grid container direction="column" className={classes.container} spacing={2}>
                            <Grid item>
                                <FormTextField multiline
                                               value={this.state.message}
                                               className='contactTextarea'
                                               variant="outlined"
                                               rows='4'
                                               inputRef={e => {
                                                   this.form = e
                                               }}
                                               inputProps={{
                                                   maxLength: 500,
                                               }}
                                               label='Enter your message'
                                               onChange={this.handleChangeMsg}
                                               required
                                />
                            </Grid>
                            <Grid item container spacing={2} alignItems="center">
                                <Grid item sm={10} xs={12}>
                                    <FormTextField value={this.state.author}
                                                   className='contactInput'
                                                   variant="outlined"
                                                   inputProps={{
                                                       maxLength: 20,
                                                   }}
                                                   label='Enter your name'
                                                   onChange={this.handleChangeName}
                                                   type='text'
                                                   required
                                    />
                                </Grid>
                                <Grid item sm={2} xs={12}
                                      style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "flex-end",
                                          height: '100%'
                                      }}>
                                    <Button variant="contained"
                                            color="primary"
                                            id='submit'
                                            size='large'
                                            style={{height: '100%'}}
                                            type='submit'>Submit</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                    {this.props.postJson.comments.map(comment => {
                        return (<Fragment key={comment.id}>
                                {getComment.call(this, classes, comment)}
                                {comment.comments.map(commentChild => {
                                    return (
                                        <Grid key={commentChild.id} container style={{paddingLeft: 32}}>
                                            {getComment.call(this, classes, commentChild)}
                                        </Grid>
                                    )
                                })
                                }
                            </Fragment>
                        );
                    })}
                </Grid>
            </Fragment>
        )
    }
}

function getComment(classes, comment) {
    return (
        <Grid container direction="column" className={classes.commentTittle}>
            <Grid item
                  container
                  direction="column"
                  spacing={0}
                  className={classes.commentContainer}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item container spacing={1} xs={8} alignItems="center" justify='flex-start'>
                        <Grid item>
                            <Typography variant="h6" paragraph
                                        style={{fontWeight: 600}}
                                        className={classes.commentTittle + " text-black"}>
                                {comment.author}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid container alignItems="center">
                                <Typography variant="caption" paragraph
                                            className={classes.commentTittle + " text-black"}>
                                    {comment.created}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} container spacing={0} justify='flex-end'>
                        <div className="postCommentLikeCount">
                            <Button
                                onClick={e => {
                                    this.onLikeComment(comment)
                                }}
                            >
                                <LikeIcon id={comment.id}/>
                                <p>{comment.likeCount}</p>
                            </Button>
                            <Button onClick={e => {
                                this.onResponse(comment)
                            }}>
                                <FaRegComment className="postCommentIcon"/>
                                <p>{comment.commentCount}</p>
                            </Button>
                        </div>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="paragraph" paragraph
                                className={classes.commentBody + " text-black"}>
                        {comment.message}
                    </Typography>
                </Grid>
            </Grid>
            <Divider/>
        </Grid>
    )
}

export function addComment(postId, commentId, author, message, cb) {
    fetch(postAddComment, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            postId: postId,
            commentId: commentId,
            author: author,
            message: message,
        })
    })
        .then(res => {
            if (res.status !== 200 && res.status !== 201) {
                throw new Error('Comment failed.');
            }
            return res.json();
        })
        .then(resData => {
            cb(resData);
        })
        .catch(err => {
            console.error(err);
        });
}

function likeComment(commentJson, cb) {
    const increase = !(getCookie("cookieComment" + commentJson.id) === 'true');
    fetch(postCommentLike, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            commentId: commentJson.id,
            increase: increase,
        })
    })
        .then(res => {
            if (res.status !== 200 && res.status !== 201) {
                throw new Error('Like failed.');
            }
            return res.json();
        })
        .then(resData => {
            if (increase) {
                commentJson.likeCount++;
            } else {
                commentJson.likeCount--;
            }
            setCookie("cookieComment" + commentJson.id, increase);
            cb(commentJson, increase);
        })
        .catch(err => {
            console.error(err);
        });
}

function LikeIcon(commentJson) {
    if (getCookie("cookieComment" + commentJson.id) === 'true') {
        return <FaHeart className="postLikeIcon"/>
    } else {
        return <FiHeart className="postLikeIcon"/>
    }
}

const FormTextField = withStyles({
    root: {
        "& .Mui-focused": {
            color: grey[700],
        },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: grey[700],
        }
    }
})(TextField);

export default withStyles(styles, {withTheme: true})(CommentPanel)