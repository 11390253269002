import React, {Component} from 'react';

import "./About.css"
import ReactMarkdown from "react-markdown";
import CodeBlock from "../post/CodeBlock";
import About_v1_Md from "./About_v1.md"
import About_v2_Md from "./About_v2.md"
import AboutSkill from "./AboutSkill";
import AboutImg from "./AboutImg.js";

class About extends Component {

    state = {
        markdown_v1: '',
        markdown_v2: '',
    };

    componentDidMount() {
        fetch(About_v1_Md).then(res => res.text()).then(text => this.setState({markdown_v1: text}));
        fetch(About_v2_Md).then(res => res.text()).then(text => this.setState({markdown_v2: text}));
    }

    render() {
        const {markdown_v1, markdown_v2} = this.state;
        return (
            <div className='wrap'>
                <div className="postBody">
                    <div className='post'>
                        <AboutImg/>
                        <ReactMarkdown
                            source={markdown_v1}
                            renderers={{
                                code: CodeBlock,
                                image: Image
                            }}
                            escapeHtml={false}
                        />
                        <AboutSkill/>
                        {/*<ReactMarkdown*/}
                        {/*    source={markdown_v2}*/}
                        {/*    renderers={{*/}
                        {/*        code: CodeBlock,*/}
                        {/*        image: Image*/}
                        {/*    }}*/}
                        {/*    escapeHtml={false}*/}
                        {/*/>*/}
                    </div>
                </div>
            </div>
        );
    }
}

function Image(props) {
    return <div className='postContentImgWrap'>
        <img {...props} className='postContentImg' alt='Img'/>
    </div>
}

export default About;