export const routeState = {
    HOME: '/',
    BLOG: '/blog',
    GITHUB: '/github',
    QUOTE: '/quote',
    ABOUT: '/about',
    CONTACT: '/contact',
    SIGN_UP: '/sign-up',
    SIGN_IN: '/sign-in',
    ADD_POST: '/add-post',
    PRIVACY_POLICY: '/privacy-policy',
};

export const routeStateAuth = {
    ADD_POST: '/add-post',
    ADD_QUOTE: '/add-quote',
};

export const routeStateAuthAdmin = {
    ADD_POST: '/add-post',
    ADD_QUOTE: '/add-quote',
};