import React, {Fragment} from "react";
import {makeStyles} from "@material-ui/styles";
import Modal from "@material-ui/core/Modal";
import Fade from '@material-ui/core/Fade';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2, 4, 3),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 400,
    },
    text: {
        color: theme.palette.text.primary
    },
    error: {
        color: theme.palette.text.primary,
        borderColor: theme.palette.text.primary
    }
}));

const PopUp = props => {
    const classes = useStyles();
    if (!props.popUp) return null;
    return (
        <Fragment>
            <Modal
                className={classes.modal}
                open={true}
                onClose={props.popUp.onOutSideClick}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Fade in={true}>
                    <div className={classes.paper}>
                        <Typography
                            variant='h4'
                            id="transition-modal-title"
                        >{props.popUp.title}</Typography>
                        <Typography
                            variant='body1'
                            style={{paddingTop: 10}}
                            id="transition-modal-description"
                        >{props.popUp.subtitle}</Typography>
                        <div style={{paddingTop: 10}}>
                            <Grid container justify='flex-end' spacing='1'>
                                {props.popUp.isCancelVisible && (
                                    <Grid item>
                                        <Button className={classes.error}
                                                variant="outlined"
                                                color="error"
                                                onClick={props.popUp.onCancelListener}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                )}
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={props.popUp.onSuccessListener}
                                    >
                                        Ok
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </Fragment>
    );
};


export default PopUp;