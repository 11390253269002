import React from 'react'
import {compose} from "recompose"
import {Link, NavLink} from "react-router-dom";
import {routeState} from "./const";
import './Navigation.css';
import SocialLinks from "../socialLinks/SocialLinks";
import {NavButton} from "../../res/values/theme";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        color: '#FFF',
        backgroundColor: '#333333',
        textTransform: 'initial',
        "&:focus": {
            outline: 0
        },
        '&:hover': {
            color: '#000',
        }
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    noDecoration: {
        textDecoration: "none !important",
        borderRadius: 8,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        }
    },
    grid: {
        flexWrap: 'initial',
    }
}));

const Navigation = compose()((props) => {
    const classes = useStyles();
    const menuItems = [
        {
            link: routeState.BLOG,
            name: "Blog",
        },
        {
            link: routeState.QUOTE,
            name: "Quotes",
        },
        {
            link: routeState.ABOUT,
            name: "About",
        },
        {
            link: routeState.CONTACT,
            name: "Contact",
        }
    ];
    return (<header className="app-header">
            <div className="box">
                <div className="name">
                    <NavButton>
                        <NavLink to={routeState.HOME}>Artur Gniewowski</NavLink>
                    </NavButton>
                </div>
            </div>
            <div className="box">
                <SocialLinks/>
            </div>
            <div className="box">
                <Grid container className={classes.grid + ' navGrid'}>
                    {menuItems.map(element => {
                        return (
                            <Link
                                key={element.name}
                                className={classes.noDecoration}
                                to={element.link}
                                onClick={() => props.state(element.link)}
                            >
                                <Button
                                    color="secondary"
                                    size="large"
                                    classes={{text: classes.root}}
                                >
                                    {element.name}
                                </Button>
                            </Link>
                        )
                    })}
                </Grid>
            </div>
        </header>
    )
});

export default Navigation