import React from 'react'
import {compose} from "recompose"
import {makeStyles} from '@material-ui/core/styles';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import red from "@material-ui/core/colors/red";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import {routeStateAuthAdmin} from "../navigation/const";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        color: '#000',
        backgroundColor: red[300],
        textTransform: 'initial',
        "&:focus": {
            outline: 0
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    noDecoration: {
        textDecoration: "none !important"
    }
}));

const AdminNavigation = compose()((props) => {
    const classes = useStyles();
    if (!props.isVisible) return null;
    const menuItems = [
        {
            link: routeStateAuthAdmin.ADD_POST,
            name: "Add Post",
        },
        {
            link: routeStateAuthAdmin.ADD_QUOTE,
            name: "Add Quote",
        }
    ];
    return (<div className={classes.root}>
            <AppBar className={classes.root} position="static">
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        Admin
                    </Typography>
                    {menuItems.map(element => {
                        return (
                            <Link
                                key={element.name}
                                className={classes.noDecoration}
                                to={element.link}
                                onClick={() => props.state(element.link)}
                            >
                                <Button
                                    color="secondary"
                                    size="large"
                                    classes={{text: classes.root}}
                                >
                                    {element.name}
                                </Button>
                            </Link>
                        );
                    })}
                </Toolbar>
            </AppBar>
        </div>
    )
});

export default AdminNavigation