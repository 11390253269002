import React, {Component, Fragment} from 'react';
import './App.css';
import {Redirect, Route, Switch, useParams} from 'react-router';
import {routeState, routeStateAuth, routeStateAuthAdmin} from "./navigation/const";
import Navigation from "./navigation/Navigation";
import Blog from "./blog/Blog";
import Home from "./home/Home";
import QuoteList from "./quote/QuoteList";
import Post from "./post/Post";
import About from "./about/About";
import Contact from "./contact/Contact";
import LoginPage from './auth/Login';
import SignUp from './auth/SignUp';
import AddPost from "./post/add_post/AddPost";
import {withRouter} from "react-router-dom";
import AddQuote from "./quote/add_quote/AddQuote";
import {User} from "./auth/data/User";
import {Role} from "./auth/data/Role";
import AdminNavigation from "./admin-navigation/AdminNavigation";
import PrivacyPolicy from "./policies/PrivacyPolicy";
import Cookie from "./policies/cookie/Cookie";
import Footer from "./footer/Footer";
import {getCookie} from "./util/CookieUtil";
import ScrollToTop from "./util/ScrollToTop.js";

const routeStateAuthSet = new Set(Object.values(routeStateAuth));
const routeStateAuthAdminSet = new Set(Object.values(routeStateAuthAdmin));

class App extends Component {
    state = {
        error: "",
        route: routeState.HOME,
        routeHistory: [],
        drawerIsActive: false,
        isMarkerShown: true,
        markerList: [],
        selected: "",
        query: '',
        query2: [],
        showSearchPage: false,
        cookieConsent: false
    };

    updateNavigationDrawer = (state) => {
        this.setState({route: state});
    };

    componentDidMount() {
        this.setCookieConsent();
    }

    setCookieConsent = () => {
        const cookieConsent = getCookie('CookieConsent');
        this.setState({cookieConsent: cookieConsent});
    };

    render() {
        return (
            <Fragment>
                <div className="App">
                    <ScrollToTop/>
                    <AdminNavigation
                        isVisible={isAdmin() && isValid()}
                        state={this.updateNavigationDrawer}
                    />
                    <div className="AppContent">
                        <Navigation state={this.updateNavigationDrawer}/>
                        <Switch>
                            <Route exact path="/" children={<Home/>}/>
                            <Route exact path="/:state" children={<GetPath/>}/>
                            <Route path="/blog/:postId">
                                <Post/>
                            </Route>
                            <Redirect to="/"/>
                        </Switch>
                    </div>
                    <Footer state={this.updateNavigationDrawer}/>
                    <Cookie isVisible={this.state.cookieConsent} onClick={this.setCookieConsent}/>
                </div>
            </Fragment>
        )
    }
}

function isAdmin() {
    return Role.admin === localStorage.getItem(User.role)
}

function isValid() {
    return localStorage.getItem(User.exp) > new Date().getTime() / 1000
}

function GetPath() {
    let content;
    let {state} = useParams();
    state = '/' + state;
    if (routeStateAuthSet.has(state) || routeStateAuthAdminSet.has(state)) {
        if (!isValid()) return <Redirect to={routeState.SIGN_IN}/>
    }

    if (routeStateAuthAdminSet.has(state)) {
        if (!isAdmin()) return <Redirect to={routeState.SIGN_IN}/>
    }

    switch (state) {
        case routeState.HOME:
            content = <Home state={state}/>;
            break;
        case routeState.BLOG:
            content = <Blog state={state}/>;
            break;
        case routeState.QUOTE:
            content = <QuoteList state={state}/>;
            break;
        case routeState.ABOUT:
            content = <About state={state}/>;
            break;
        case routeState.CONTACT:
            content = <Contact state={state}/>;
            break;
        case routeState.PRIVACY_POLICY:
            content = <PrivacyPolicy state={state}/>;
            break;
        case routeState.SIGN_UP:
            content = <SignUp/>;
            break;
        case routeState.SIGN_IN:
            content = <LoginPage/>;
            break;
        case routeStateAuth.ADD_POST:
            content = <AddPost/>;
            break;
        case routeStateAuth.ADD_QUOTE:
            content = <AddQuote/>;
            break;
        default:
            content = <Home state={state}/>;
    }
    return content

}

export default withRouter(App);
