import React, {Component} from 'react'
import "./Quote.css"
import {withRouter} from "react-router-dom";
import {quoteLike, quotesUrl} from "../util/BlogApi";
import Button from "@material-ui/core/Button";
import {getCookie, setCookie} from "../util/CookieUtil";
import {FaHeart, FiHeart} from "react-icons/all";

class QuoteList extends Component {

    state = {
        quotes: []
    };

    componentDidMount() {
        fetch(quotesUrl)
            .then(res => {
                if (res.status !== 200) {
                    throw new Error('Failed to fetch quotes.');
                }
                return res.json()
            })
            .then(res => {
                this.setState({quotes: res.reverse()});
            })
            .catch(error => {
                console.log(error);
            });
    }

    mouseDown = e => {
        e.stopPropagation();
    };

    onLikeClick = (event, quote) => {
        event.preventDefault();
        likePost(quote, (quoteJson, newLike) => {
                this.setState({
                    newLike: newLike,
                    postJson: quoteJson
                });
            }
        );
    };

    render() {
        return (
            <div className="homeContainer2">
                <div className="quoteList">
                    <ul>
                        {this.state.quotes.map((quote) => (
                            <li className="quote" key={quote.id}>
                                <div className="quoteBody">{quote.quote}</div>
                                <div className="quoteRowBottom">
                                    <div className="postRowLikeCount">
                                        <Button
                                            onMouseDown={this.mouseDown}
                                            onClick={e => this.onLikeClick(e, quote)}>
                                            <LikeIcon id={quote.id}/>
                                            <p>{quote.likeCount} </p>
                                        </Button>
                                    </div>
                                    <div className="postDate">{quote.author}</div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    }
}

function likePost(quoteJson, cb) {
    const increase = !(getCookie("cookieQuote" + quoteJson.id) === 'true');
    fetch(quoteLike, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            quoteId: quoteJson.id,
            increase: increase,
        })
    })
        .then(res => {
            if (res.status !== 200 && res.status !== 201) {
                throw new Error('Like failed.');
            }
            return res.json();
        })
        .then(resData => {
            if (increase) {
                quoteJson.likeCount++;
            } else {
                quoteJson.likeCount--;
            }
            setCookie("cookieQuote" + quoteJson.id, increase);
            cb(quoteJson, increase);
        })
        .catch(err => {
            console.error(err);
        });
}

function LikeIcon(quoteJson) {
    if (getCookie("cookieQuote" + quoteJson.id) === 'true') {
        return <FaHeart className="postRowLikeIcon"/>
    } else {
        return <FiHeart className="postRowLikeIcon"/>
    }
}

export default withRouter(QuoteList)