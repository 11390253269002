let baseUrl = "";
if (process.env.REACT_APP_BLOG_SERVER) {
    baseUrl = process.env.REACT_APP_BLOG_SERVER
} else {
    // baseUrl = 'https://arturgniewowski.com/backend';
    // baseUrl = 'http://192.168.1.104:3000';
    baseUrl = 'http://localhost:3000';
}

//unAuth
export const subscribeUrl = baseUrl + '/subscribe';
export const contactUrl = baseUrl + '/contact';
export const postsUrl = baseUrl + '/posts';
export const postByIdUrl = baseUrl + '/posts/{0}';
export const postLike = baseUrl + '/posts/likePost';
export const postCommentLike = baseUrl + '/posts/likeComment';
export const postAddComment = baseUrl + '/posts/addComment';
export const quotesUrl = baseUrl + '/quotes';
export const quoteLike = baseUrl + '/quotes/likeQuote';
//auth
export const signUpUrl = baseUrl + '/auth/signUp';
export const signInUrl = baseUrl + '/auth/signIn';
export const addPostUrl = baseUrl + '/posts/addPost';
export const addQuoteUrl = baseUrl + '/quotes/addQuote';
//res
export const postUrl = baseUrl + '/res/post/{0}_{1}';
export const postIconUrl = baseUrl + '/res/postIcon/{0}';
export const postBannerUrl = baseUrl + '/res/postBanner/{0}';