import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import "./Post.css"
import ReactMarkdown from 'react-markdown';
import CodeBlock from './CodeBlock'
import {formatDate} from "../util/Util";
import {format} from 'react-string-format';
import {postBannerUrl, postByIdUrl, postLike, postUrl} from "../util/BlogApi";
import {FiHeart} from 'react-icons/fi';
import {FaHeart, FaRegComment} from 'react-icons/fa';
import Button from "@material-ui/core/Button";
import {getCookie, setCookie} from "../util/CookieUtil";
import CommentPanel from "../comment-panel/CommentPanel";
import moment from "moment";

class Post extends Component {

    postId;
    postName;
    postBanner;
    likeCount;
    commentCount;
    comments;
    state = {
        newLike: false,
        markdown: '',
        postJson: {comments: []}
    };

    componentDidMount() {
        let postId = this.props.match.params.postId;
        // this.setState({postJson: this.props.location.state.post});
        fetch(format(postByIdUrl, postId))
            .then(res => {
                if (res.status !== 200) {
                    throw new Error('Failed to fetch posts.');
                }
                return res.json()
            })
            .then(res => {
                this.formatComments(res.comments);
                this.setState({postJson: res});
                this.getPost();
            })
            .catch(error => {
                console.log(error);
            });
    }

    getPost() {
        fetch(format(postUrl, this.state.postJson.date, this.state.postJson.postName))
            .then(res => {
                if (res.status !== 200) {
                    throw new Error('Failed to fetch post.');
                }
                return res.text()
            })
            .then(res => {
                this.setState({markdown: res})
            })
            .catch(error => {
                console.log(error);
            });
    }

    getBanner(postJson) {
        if (!postJson.postBanner) return postJson.postBanner;
        return format(postBannerUrl, postJson.postBanner);
    }

    onLikeClick = (event) => {
        const {postJson} = this.state;
        likePost(
            postJson, (postJson, newLike) => {
                this.setState({
                    newLike: newLike,
                    postJson: postJson
                });
            }
        );
    };

    updateCommentCount = (comments) => {
        let postJson = this.state.postJson;
        postJson.commentCount++;
        postJson.comments = this.formatComments(comments);
        this.setState({postJson: postJson});
    };

    formatComments = (comments) => {
        if (comments) {
            this.setDate(comments);
            return comments.reverse();
        }
        return []
    };

    setDate(comments) {
        if (comments) {
            for (let comment of comments) {
                this.setDateOnComment(comment);
                this.setDate(comment.comments)
            }
        }
    }

    setDateOnComment(comment) {
        const date = new Date(comment.createdAt).getTime();
        if (date) {
            comment.created = moment(date).fromNow();
        }
    }

    render() {
        const {markdown} = this.state;
        const {postJson} = this.state;
        return (
            <div className='wrap'>
                <img className='banner' src={this.getBanner(postJson)} alt=''/>
                <div className="postBody">
                    <div className='post'>
                        <div className='postHeader'>
                            <div className='postDate'>{formatDate(postJson.date)}</div>
                        </div>
                        <ReactMarkdown
                            source={markdown}
                            renderers={{
                                code: CodeBlock,
                                image: Image
                            }}
                            escapeHtml={false}
                        />
                        <div className="postBottom">
                            <div className='postAuthor'>{'- ' + postJson.author}</div>
                            <div className="postLikeCount">
                                <Button
                                    onClick={this.onLikeClick}
                                >
                                    <LikeIcon id={postJson.id}/>
                                    <p>{postJson.likeCount} </p>
                                </Button>
                                <Button>
                                    <FaRegComment className="postCommentIcon"/>
                                    <p>{postJson.commentCount} </p>
                                </Button>
                            </div>
                        </div>
                        <CommentPanel
                            postJson={postJson}
                            updateCommentCount={this.updateCommentCount}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export function likePost(postJson, cb) {
    const increase = !(getCookie("cookiePost" + postJson.id) === 'true');
    fetch(postLike, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            postId: postJson.id,
            increase: increase,
        })
    })
        .then(res => {
            if (res.status !== 200 && res.status !== 201) {
                throw new Error('Like failed.');
            }
            return res.json();
        })
        .then(resData => {
            if (increase) {
                postJson.likeCount++;
            } else {
                postJson.likeCount--;
            }
            setCookie("cookiePost" + postJson.id, increase);
            cb(postJson, increase);
        })
        .catch(err => {
            console.error(err);
        });
}

function LikeIcon(postJson) {
    if (getCookie("cookiePost" + postJson.id) === 'true') {
        return <FaHeart className="postLikeIcon"/>
    } else {
        return <FiHeart className="postLikeIcon"/>
    }
}

function Image(props) {
    return <img {...props} className='postContentImgWrap postContentImg' alt='Img'/>
}

export default withRouter(Post)