import React from 'react';

import "./Home.css"
import "./HomePage2.css"

const HomePage2 = () => {
    return (
        <div className="home-item home-view-2">
            <div className='homeContent2'>
                <div className="homeWrap">
                    <div className="homeText homeText2">Learn Android & Web Development</div>
                    <div className="homeSubtext homeSubtext2">from beginner to expert</div>
                </div>
            </div>
        </div>
    );
};

export default HomePage2;