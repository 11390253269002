import React from "react";
import {makeStyles} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import PropTypes from 'prop-types';

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    appBar: {
        borderRadius: 8,
        background: 'linear-gradient(45deg, #333 00%, #333 50%, #333 100%)',
    },
    tabs: {
        color: '#fff',
        '&.Mui-selected': {
            color: '#000',
        },
        borderRadius: 8,
    },
    tab: {
        color: '#fff',
        '&.Mui-selected': {
            color: '#00C853',
        },
        "&:focus": {
            outline: 0
        },
        textTransform: 'initial'

    },
    indicator: {
        backgroundColor: '#00C853',
    },
    tabPanel: {
        height: 380,
        [theme.breakpoints.up("sm")]: {
            height: 300,
        }
    }
}));

const AboutSkill = () => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div className={classes.root}>
            <AppBar className={classes.appBar} position="static">
                <Tabs
                    className={classes.tabs}
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    scrollButtons="on"
                    classes={{
                        indicator: classes.indicator
                    }}>
                    textColor={'inherit'}
                    aria-label="scrollable force tabs example"
                    >
                    <Tab className={classes.tab} label="Android" {...a11yProps(0)} />
                    <Tab className={classes.tab} label="JavaScript" {...a11yProps(1)} />
                    <Tab className={classes.tab} label="Python"  {...a11yProps(2)} />
                    <Tab className={classes.tab} label="Others"  {...a11yProps(3)} />
                </Tabs>
            </AppBar>
            <TabPanel className={classes.tabPanel} value={value} index={0}>
                <li>Java, Kotlin, XML</li>
                <li>MVC, MVI, MVP, MVVM, Clean</li>
                <li>Jetpack, Compose</li>
                <li>Coroutines, RxJava</li>
                <li>Dagger, Hilt, Koin</li>
                <li>Espresso, Mockito</li>
                <li>Glide</li>
                <li>Gson, Moshi</li>
                <li>Retrofit, OkHttp</li>
                <li>Mobile Games (libGDX, Tiled, Box2d)</li>
                <li>Google Play, Google Play Games Services</li>
            </TabPanel>
            <TabPanel className={classes.tabPanel} value={value} index={1}>
                <li>Node</li>
                <li>Express</li>
                <li>Multer</li>
                <li>Sequelize, Mongoose</li>
                <li>React</li>
                <li>Material-UI</li>
            </TabPanel>
            <TabPanel className={classes.tabPanel} value={value} index={2}>
                <li>Deep Learning, Machine Learning (PyTorch, SciKit-Learn, TensorFlow)</li>
                <li>NumPy, Pandas</li>
                <li>Matplotlib</li>
                <li>Flask</li>
                <li>Requests</li>
            </TabPanel>
            <TabPanel className={classes.tabPanel} value={value} index={3}>
                <li>Jira</li>
                <li>Version-Control (Bitbucket, Git, GitHub, GitLab)</li>
                <li>Docker</li>
                <li>Nginx</li>
                <li>Firebase</li>
                <li>MongoDB, MySQL, PostgreSQL, SQLite</li>
                <li>Selenium</li>
            </TabPanel>
        </div>
    );
};

export default AboutSkill;