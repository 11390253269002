import React, {Component} from "react";
import PrivacyPolicyPopUpyMd from "./PrivacyPolicyPopUpy.md";
import "./Cookie.css"
import Button from "@material-ui/core/Button";
import ReactMarkdown from "react-markdown";
import CodeBlock from "./CodeBlock";
import {withStyles} from "@material-ui/styles";
import {setCookie} from "../../util/CookieUtil";

class Cookie extends Component {

    state = {
        markdown: '',
    };

    componentDidMount() {
        const header = document.querySelector('.cookieHeaderContainer');
        document.querySelector('.cookieContentContainer').addEventListener("scroll", (event) => {
            if (event.target.scrollTop === 0) {
                header.classList.remove('cookieHeaderContainerShadow')
            } else {
                header.classList.add('cookieHeaderContainerShadow')
            }
        });
        fetch(PrivacyPolicyPopUpyMd).then(res => res.text()).then(text => this.setState({markdown: text}));
    }

    setCookieConsent = () => {
        setCookie("CookieConsent", true);
        this.props.onClick()
    };

    render() {
        if (this.props.isVisible) return null;
        const {markdown} = this.state;
        return (
            <div className='cookieContainerFixed'>
                <div className='cookieContainer'>
                    <div className='cookieContainerRel'>
                        <div className='cookieHeaderContainer'>
                            <img src={'logo.jpg'} alt='logo'/>
                        </div>
                        <div className='cookieContentContainer'>
                            <ReactMarkdown
                                source={markdown}
                                renderers={{
                                    code: CodeBlock,
                                    image: Image
                                }}
                                escapeHtml={false}
                            />
                        </div>
                        <div className='cookieButtonContainer'>
                            <PolicyButton
                                onClick={this.setCookieConsent}
                                variant="contained"
                                color="primary">
                                Accept
                            </PolicyButton>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const PolicyButton = withStyles({
    root: {
        textTransform: 'initial',
        "&:focus": {
            outline: 0
        },
    }
})(Button);

export default Cookie;