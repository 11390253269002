import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {routeState} from "../navigation/const";
import "../contact/Contact.css"
import {User} from "./data/User";
import {signInUrl} from "../util/BlogApi";

class Login extends Component {
    state = {
        username: "",
        password: ""
    };

    setUsername = (event) => {
        this.setState({username: event.target.value});
    };

    setPassword = (event) => {
        this.setState({password: event.target.value});
    };

    setLogin = (event) => {
        event.preventDefault();
        fetch(signInUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: this.state.username,
                password: this.state.password
            })
        })
            .then(res => {
                if (res.status === 422) {
                    throw new Error('Validation failed.');
                }
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error('Could not authenticate you!');
                }
                return res.json();
            })
            .then(resData => {
                console.log(resData);
                localStorage.setItem(User.token, resData.token);
                localStorage.setItem(User.exp, resData.exp);
                localStorage.setItem(User.userId, resData.userId);
                localStorage.setItem(User.role, resData.role);
                this.props.history.push(routeState.ADD_POST)
            })
            .catch(err => {
                console.error(err);
            });
    };

    render() {
        return (
            <div className="contact">
                <div className='contactCard'>
                    <div className='title'>Sign In</div>
                    <form onSubmit={this.setLogin}>
                        <div className='contactContainer'>
                            <div className='inputWrap'>
                                <input className="contactInput"
                                       placeholder="Your E-Mail"
                                       type="text"
                                       onChange={this.setUsername}
                                />
                            </div>
                            <div className='inputWrap'>
                                <input className="contactInput"
                                       placeholder="Password"
                                       type="password"
                                       onChange={this.setPassword}
                                />
                            </div>
                            <input className='button' id='submit' type='submit' value='Login'/>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default withRouter(Login);
