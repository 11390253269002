import React, {Component} from 'react';
import "./AddQuote.css"
import "../../contact/Contact.css"
import {withRouter} from 'react-router-dom'
import {addQuoteUrl} from "../../util/BlogApi";

class AddQuote extends Component {

    state = {
        quote: "",
        author: "",
    };


    setQuote = (event) => {
        this.setState({quote: event.target.value});
    };

    setAuthor = (event) => {
        this.setState({author: event.target.value});
    };

    addQuote = (event) => {
        event.preventDefault();
        fetch(addQuoteUrl, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                quote: this.state.quote,
                author: this.state.author,
            })
        })
            .then(res => {
                if (res.status === 422) {
                    throw new Error("Validation failed. Make sure the email address isn't used yet!");
                }
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error('Creating a user failed!');
                }
                return res.json();
            })
            .then(resData => {
                console.log(resData)
            })
            .catch(err => {
                console.error(err);
            });
    };

    render() {
        return (
            <div className="contact">
                <div className='contactCard'>
                    <div className='title'>Add Quote</div>
                    <form onSubmit={this.addQuote}>
                        <div className='contactContainer'>
                            <div className='inputWrap'>
                                <input className="contactInput"
                                       placeholder="Quote"
                                       type="text"
                                       onChange={this.setQuote}
                                />
                            </div>
                            <div className='inputWrap'>
                                <input className="contactInput"
                                       placeholder="Author"
                                       type="text"
                                       onChange={this.setAuthor}
                                />
                            </div>
                            <input className='button' id='submit' type='submit' value='Add quote'/>
                        </div>
                    </form>
                    <div className="contact">
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(AddQuote);
