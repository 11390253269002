import {compose} from "recompose";
import {routeState} from "../navigation/const";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import "./Footer.css"
import "./SocialLinksFooter.css"
import Grid from "@material-ui/core/Grid";
import {withWidth} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Links from "../socialLinks/Links";
import Icons from "../socialLinks/Icons";
import Button from "@material-ui/core/Button";
import grey from "@material-ui/core/colors/grey";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    footerInner: {
        backgroundColor: theme.palette.common.darkBlack,
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
            paddingTop: theme.spacing(2),
            paddingLeft: theme.spacing(10),
            paddingRight: theme.spacing(10),
            paddingBottom: theme.spacing(2)
        }
    },
    root: {
        padding: 6,
        minWidth: 48,
        color: grey[50],
        textTransform: 'initial',
        "&:focus": {
            outline: 0
        },
        '&:hover': {
            color: grey[900],
            backgroundColor: theme.palette.primary.main,
        }
    },
    noDecoration: {
        margin: 0,
        textDecoration: "none !important"
    }
}));

const Footer = compose()((props) => {
    const classes = useStyles();
    const websiteItems = [
        {
            link: routeState.HOME,
            name: "Home",
        },
        {
            link: routeState.BLOG,
            name: "Blog",
        },
        {
            link: routeState.ABOUT,
            name: "About",
        }
    ];
    const contactForm = {
        link: routeState.CONTACT,
        name: "Contact-Form",
    };
    const privacyPolicy = {
        link: routeState.PRIVACY_POLICY,
        name: "Privacy Policy",
    };
    return (<footer className={classes.footerInner}>
            <Grid container>
                <Grid className={classes.footerInner} item xs={12} sm={4} md={4} lg={4}>
                    <Typography variant="h6" paragraph className={classes.noDecoration + " text-white"}>
                        Website
                    </Typography>
                    {websiteItems.map(element => {
                        return (<Grid item key={element.name}>
                                <Link
                                    className={classes.noDecoration}
                                    to={element.link}
                                    onClick={() => props.state(element.link)}
                                >
                                    {element.name}
                                </Link>
                            </Grid>
                        )
                    })}
                </Grid>
                <Grid className={classes.footerInner} item xs={12} sm={4} md={4} lg={4}>
                    <Typography variant="h6" paragraph className={classes.noDecoration + " text-white"}>
                        Contact
                    </Typography>
                    <Grid item>
                        <Link
                            key={contactForm.name}
                            className={classes.noDecoration}
                            to={contactForm.link}
                            onClick={() => props.state(contactForm.link)}
                        >
                            {contactForm.name}
                        </Link>
                    </Grid>
                    <Grid item>
                        <a href="mailto:artur.gniewowski@gmail.com">artur.gniewowski@gmail.com</a>
                    </Grid>
                    <Grid container className="socialLinksFooter">
                        {Links.map((link, i) => {
                            const Icon = Icons[link.icon];
                            return (
                                <Grid item key={link.label}>
                                    <a className={classes.noDecoration} href={link.url} title={link.label}>
                                        <Button
                                            className={classes.root}
                                            color="secondary"
                                            size="large"
                                            classes={{text: classes.root}}
                                        >
                                            <Icon/>
                                        </Button>
                                    </a>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
                <Grid className={classes.footerInner} item xs={12} sm={4} md={4} lg={4}>
                    <Typography variant="h6" paragraph className={classes.noDecoration + " text-white"}>
                        Terms & Policies
                    </Typography>
                    <Link
                        key={privacyPolicy.name}
                        className={classes.noDecoration}
                        to={privacyPolicy.link}
                        onClick={() => props.state(privacyPolicy.link)}
                    >
                        {privacyPolicy.name}
                    </Link>
                </Grid>
            </Grid>
        </footer>
    )
});

export default withWidth()(Footer)