import React, {Component} from 'react';
import {withRouter} from 'react-router-dom'
import {routeState} from "../navigation/const";
import "../contact/Contact.css"
import {signUpUrl} from "../util/BlogApi";

class SignUp extends Component {

    state = {
        email: "",
        username: "",
        password: "",
        redirect: false
    };


    setUsername = (event) => {
        this.setState({username: event.target.value});
    };

    setPassword = (event) => {
        this.setState({password: event.target.value});
    };

    setEmail = (event) => {
        this.setState({email: event.target.value});
    };

    setSignUp = (event) => {
        event.preventDefault();
        fetch(signUpUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: this.state.email,
                username: this.state.username,
                password: this.state.password
            })
        })
            .then(res => {
                if (res.status === 422) {
                    throw new Error("Validation failed. Make sure the email address isn't used yet!");
                }
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error('Creating a user failed!');
                }
                return res.json();
            })
            .then(resData => {
                this.props.history.push(routeState.SIGN_IN)
            })
            .catch(err => {
                console.error(err);
            });
    };

    render() {
        return (
            <div className="contact">
                <div className='contactCard'>
                    <div className='title'>Sign Up</div>
                    <form onSubmit={this.setSignUp}>
                        <div className='contactContainer'>
                            <div className='inputWrap'>
                                <input className="contactInput"
                                       placeholder="Your E-Mail"
                                       type="text"
                                       onChange={this.setEmail}
                                />
                            </div>
                            <div className='inputWrap'>
                                <input className="contactInput"
                                       placeholder="Username"
                                       type="text"
                                       onChange={this.setUsername}
                                />
                            </div>
                            <div className='inputWrap'>
                                <input className="contactInput"
                                       placeholder="Password"
                                       type="password"
                                       onChange={this.setPassword}
                                />
                            </div>
                            <input className='button' id='submit' type='submit' value='Sign up'/>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default withRouter(SignUp);
