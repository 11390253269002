import React from 'react';

import "./Home.css"
import "./HomePage1.css"

const HomePage1 = (props) => {
    return (
        <div className="home-item home-view-1">
            <div className=' homeContent'>
                <div className="homeWrap">
                    <div className="homeText homeText1 titleAnim ">Artur Gniewowski</div>
                    <div className="homeSubtext homeSubtext1 subtitleAnim">- Software Engineer</div>
                </div>
                {/*<div className=' homeSubscribe'>*/}
                {/*    <Subscribe popUp={props.popUp}/>*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default HomePage1;