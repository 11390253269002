import React, {Component} from 'react';
import "./AddPost.css"
import "../../contact/Contact.css"
import {withRouter} from 'react-router-dom'
import {addPostUrl} from "../../util/BlogApi";

class AddPost extends Component {

    state = {
        title: "",
        summary: "",
        author: "",
        date: "",
        postName: "",
        postName64: "",
        postBanner: "",
        postBanner64: "",
        icon: "",
        icon64: "",
    };


    setTitle = (event) => {
        this.setState({title: event.target.value});
    };

    setSummary = (event) => {
        this.setState({summary: event.target.value});
    };

    setAuthor = (event) => {
        this.setState({author: event.target.value});
    };

    setDate = (event) => {
        this.setState({date: event.target.value});
    };

    setPostName = (event) => {
        const inputText = document.querySelector("label[for='PostName']").firstChild;
        inputText.textContent = event.target.files[0].name;
        this.setState({postName: event.target.files[0].name});
        this.setState({postName64: event.target.files[0]});
    };

    setPostBanner = (event) => {
        const inputText = document.querySelector("label[for='PostBanner']").firstChild;
        inputText.textContent = event.target.files[0].name;
        this.setState({postBanner: event.target.files[0].name});
        this.setState({postBanner64: event.target.files[0]});
    };

    setIcon = (event) => {
        const inputText = document.querySelector("label[for='Icon']").firstChild;
        inputText.textContent = event.target.files[0].name;
        this.setState({icon: event.target.files[0].name});
        this.setState({icon64: event.target.files[0]});
    };

    addPost = (event) => {
        event.preventDefault();
        console.log(this.state.title);
        const formData = new FormData();
        formData.append('title', this.state.title);
        formData.append('summary', this.state.summary);
        formData.append('author', this.state.author);
        formData.append('date', this.state.date);
        formData.append('postName', this.state.postName);
        formData.append('postName64', this.state.postName64);
        formData.append('postBanner', this.state.postBanner);
        formData.append('postBanner64', this.state.postBanner64);
        formData.append('icon', this.state.icon);
        formData.append('icon64', this.state.icon64);
        console.log(formData);
        fetch(addPostUrl, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            },
            body: formData
        })
            .then(res => {
                if (res.status === 422) {
                    throw new Error("Validation failed. Make sure the email address isn't used yet!");
                }
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error('Creating a user failed!');
                }
                return res.json();
            })
            .then(resData => {
                console.log(resData)
            })
            .catch(err => {
                console.error(err);
            });
    };

    render() {
        return (
            <div className="contact">
                <div className='contactCard'>
                    <div className='title'>Add Post</div>
                    <form onSubmit={this.addPost}>
                        <div className='contactContainer'>
                            <div className='inputWrap'>
                                <input className="contactInput"
                                       placeholder="Title"
                                       type="text"
                                       onChange={this.setTitle}
                                />
                            </div>
                            <div className='inputWrap'>
                                <input className="contactInput"
                                       placeholder="Summary"
                                       type="text"
                                       onChange={this.setSummary}
                                />
                            </div>
                            <div className='inputWrap'>
                                <input className="contactInput"
                                       placeholder="Author"
                                       type="text"
                                       onChange={this.setAuthor}
                                />
                            </div>
                            <div className='inputWrap'>
                                <input className="contactInput"
                                       placeholder="yyyy-mm-dd"
                                       type="text"
                                       onChange={this.setDate}
                                />
                            </div>
                            <div className='inputWrap'>
                                <input
                                    id="PostName"
                                    className="contactInput"
                                    placeholder="PostName"
                                    type="file"
                                    onChange={this.setPostName}
                                />
                                <label className="contactInput" htmlFor="PostName">
                                    <p className="inputFileText">No post chosen</p>
                                    <p className="inputFileButton">Choose File</p>
                                </label>
                            </div>
                            <div className='inputWrap'>
                                <input
                                    id="PostBanner"
                                    className="contactInput"
                                    placeholder="PostBanner"
                                    type="file"
                                    onChange={this.setPostBanner}
                                />
                                <label className="contactInput" htmlFor="PostBanner">
                                    <p className="inputFileText">No banner chosen</p>
                                    <p className="inputFileButton">Choose File</p>
                                </label>
                            </div>
                            <div className='inputWrap'>
                                <input
                                    id="Icon"
                                    className="contactInput"
                                    placeholder="Icon"
                                    type="file"
                                    onChange={this.setIcon}
                                />
                                <label className="contactInput" htmlFor="Icon">
                                    <p className="inputFileText">No icon chosen</p>
                                    <p className="inputFileButton">Choose File</p>
                                </label>
                            </div>
                            <input className='button' id='submit' type='submit' value='Add post'/>
                        </div>
                    </form>
                    <div className="contact">
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(AddPost);
