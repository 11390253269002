import {FacebookSquare, Github, LinkedinSquare, Medium, Twitter} from '@styled-icons/boxicons-logos';

const Icons = {
    Github,
    Twitter,
    FacebookSquare,
    LinkedinSquare,
    Medium
};

export default Icons;