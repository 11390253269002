import React from "react";
import {makeStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
    },
    avatar: {
        flexGrow: 1,
        width: 240,
        height: 240,
        borderRadius: '50%',
        backgroundColor: theme.palette.background.paper,
    }
}));

const AboutImg = () => {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <Box>
                <img className={classes.avatar} src={'https://avatars.githubusercontent.com/u/25232443?v=4'}
                     alt="avatar"/>
            </Box>
        </Box>
    );
};

export default AboutImg;