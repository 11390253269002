import React, {Fragment} from "react";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2, 4, 3),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 400,
        height: 150,
        justifyContent: 'center',
        alignItems: 'center',
    }
}));

const ProgressPopUp = props => {
    const classes = useStyles();
    if (!props.progress) return null;
    return (
        <Fragment>
            <Modal
                className={classes.modal}
                open={true}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Fade in={true}>
                    <Grid container className={classes.paper} spacing='1'>
                        <Grid item>
                            <CircularProgress color="secondary"/>
                        </Grid>
                    </Grid>
                </Fade>
            </Modal>
        </Fragment>
    );
};


export default ProgressPopUp;