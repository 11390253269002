import {createMuiTheme} from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import {withStyles} from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import {black, darkBlack} from "./colors";

export const theme = createMuiTheme({
    palette: {
        primary: {main: green['A700']},
        secondary: {main: green[500]},
        common: {
            black,
            darkBlack
        },
    }
});

export const NavButton = withStyles({
    root: {
        variant: 'text',
        textTransform: 'initial',
        '&.MuiButton-text': {
            padding: 0,
        },
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
        '&:focus': {
            outline: 0,
        },
    }
})(Button);