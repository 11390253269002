/* eslint-env jquery */
import React, {Component, Fragment} from 'react';
import {tns} from "../../../../node_modules/tiny-slider/src/tiny-slider"
import "./TinySlider.css"
import HomePage1 from "./HomePage1";
import HomePage2 from "./HomePage2";
import HomePage3 from "./HomePage3";
import PopUp from "../components/PopUp";
import {PopUpState} from "../components/PopUpState";

class TinySlider extends Component {

    state = {
        popUp: null
    };

    onChange = (e) => {
        let index = e.displayIndex;
        document.querySelectorAll('.homeText').forEach((element) => {
            element.classList.remove('titleAnim');
        });
        document.querySelectorAll('.homeSubtext').forEach((element) => {
            element.classList.remove('subtitleAnim');
        });
        let titleView = '.homeText.homeText' + index;
        let subtitleView = '.homeSubtext.homeSubtext' + index;
        $(titleView).addClass('titleAnim');
        $(subtitleView).addClass('subtitleAnim');
        this.slider.play()
    };

    createSlider = () => {
        if (this.slider) return;
        this.slider = tns({
            touch: false,
            preventScrollOnTouch: true,
            mode: 'gallery',
            container: '.tinySlider',
            items: 1,
            autoplayButtonOutput: false,
            controls: true,
            mouseDrag: true,
            slideBy: 'page',
            animateNormal: 'titleAnim',
            autoplay: true,
            autoplayTimeout: 6000,
            loop: true
        });
        const {events} = this.slider;
        events.on('indexChanged', this.onChange);
    };

    handleSubscribeFocus = () => {
        let subscribe = $('.subscribe');
        subscribe.on('click', () => {
            this.slider.pause();
        });
        const input = $('.email');
        input.on('focusout', () => {
            const validity = Array.from(input).map((element) => {
                return element.checkValidity();
            });
            const validitySet = new Set(validity);
            if (validitySet.has(true)) {
                this.slider.play()
            } else {
                this.slider.pause();
            }
        });

        $('.tns-nav').on('click', () => {
            this.slider.play()
        });
    };

    handleDrag = () => {
        if (this.touchstartX - this.touchendX > 20) {
            this.slider.goTo('next');
        }
        if (this.touchendX - this.touchstartX > 20) {
            this.slider.goTo('prev');
        }
    };

    touchStart = (e) => {
        this.touchstartX = e.changedTouches[0].screenX;
        this.slider.pause();
    };

    touchend = (e) => {
        this.touchendX = e.changedTouches[0].screenX;
        this.slider.play();
        this.handleDrag()
    };

    addArrows() {
        document.querySelector('button[data-controls="prev"]').textContent = '';
        document.querySelector('button[data-controls="next"]').textContent = '';
        let newRightElement = document.createElement('i');
        let newLeftElement = document.createElement('i');
        newRightElement.classList.add('fas', 'fa-angle-right');
        newLeftElement.classList.add('fas', 'fa-angle-left');
        document.querySelector('button[data-controls="next"]').appendChild(newRightElement);
        document.querySelector('button[data-controls="prev"]').appendChild(newLeftElement);
    }

    componentDidMount() {
        this.createSlider();
        this.handleSubscribeFocus();
        this.addArrows();

        document.querySelector('.tinySlider').addEventListener('touchstart', this.touchStart);
        document.querySelector('.tinySlider').addEventListener('touchend', this.touchend)
    }

    componentWillUnmount() {
        if (this.slider) this.slider.destroy();
    }

    showPopUp = (title, subtitle, isCancelVisible) => {
        const testModalState = new PopUpState(
            title,
            subtitle,
            isCancelVisible,
            () => {
                this.setState({popUp: null})
            },
            () => {
                this.setState({popUp: null})
            },
            () => {
                this.setState({popUp: null})
            },
        );
        this.setState({popUp: testModalState});
    };

    render() {
        return (
            <Fragment>
                <PopUp popUp={this.state.popUp}/>
                <div className="tinySlider">
                    <HomePage1 popUp={this.showPopUp}/>;
                    <HomePage2/>;
                    <HomePage3/>;
                </div>
            </Fragment>
        )
    }
}

export default TinySlider